import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取商户信息
export function getBusinessInfo(language) {
  return request({
    url: `/admin/business/info/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 更新商户信息
export function updateBusinessInfo(data) {
  return request({
    url: '/admin/business/update/info',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取资讯列表
export function getNewsList() {
  return request({
    url: `/admin/business/news/list/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 保存资讯
export function saveNews(data) {
  return request({
    url: '/admin/business/news/save',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取资讯详情
export function getNewsDetail(newsId, language) {
  return request({
    url: `/admin/business/news/details/${newsId}/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 删除资讯
export function deleteNews(newsId) {
  return request({
    url: `/admin/business/news/delete/${newsId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 置顶资讯
export function setNewsTop(newsId) {
  return request({
    url: `/admin/business/news/top/${newsId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 获取商家列表
export function getBusinessList(data) {
  return request({
    url: `/admin/business/home/list/${language}`,
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取商家模块公共数据
export function getBusinessDetail(userId) {
  return request({
    url: `/admin/business/home/common/${language}/${userId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取商家产品列表
export function getBusinessProductList(userId, data) {
  return request({
    url: `/admin/business/home/product/list/${language}/${userId}`,
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取资讯详情
export function getBusinessNewsDetail(newsId) {
  return request({
    url: `/admin/business/web/news/details/${newsId}/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}
