<template>
  <div class="main m-l">
    <business-info/>
    <div class="list">
      <div class="hd">
        <div class="fl">{{$t('My homepage ad')}}</div>
        <div class="fr">
          <a class="btn" @click="toAdd">{{$t('New')}}</a>
        </div>
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
<!--            <th>{{$t('Number')}}</th>-->
            <th>{{$t('Title')}}</th>
            <th>{{$t('Release date')}}</th>
            <th>{{$t('Operating')}}</th>
            <th>{{$t('Top')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) of newsList" :key="item.newsId">
<!--            <td>{{item.newsCode}}</td>-->
            <td>{{item.title}}</td>
            <td>{{item.updateDate.substring(0,10)}}</td>
            <td class="nowrap">
              <el-dropdown style="margin-right:8px;" trigger="click" @command="toEdit">
                <div>
                  <a class="btn-item" href="javascript:;">
                    <img src="../../../assets/images/icon/pen.png"/>
                  </a>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="lang of languageOption"
                                    :command="{language:lang.value,newsId:item.newsId}"
                                    :key="lang.value">
                    {{lang.name}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <a class="btn-item" href="javascript:;" @click="deleteNews(index,item.newsId)">
                <img src="../../../assets/images/icon/ashcan.png"/>
              </a>
            </td>
            <td>
              <a class="btn-item " href="javascript:;" @click="setTop(item.newsId)">
                <img v-if="index === 0" src="../../../assets/images/radio_selected.png" class="img-responsive"/>
                <img v-else src="../../../assets/images/radio.png" class="img-responsive"/>
              </a>

            </td>

          </tr>
          <tr v-if="isEmpty">
            <td colspan="5">
              <empty/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import Empty from '../../../components/Empty';
  import BusinessInfo from './components/BusinessInfo';
  import {languageOptions} from '../../../utils/language';
  import {getNewsList, deleteNews, setNewsTop} from '../../../api/admin-business';

  export default {
    name: 'AdminInformation',
    components: {Empty, BusinessInfo},
    data() {
      return {
        newsList: [],
        isEmpty: false,
        languageOption: languageOptions,
        isLoading: true,
        maxLength: 5
      };
    },
    created() {
      this.getNewsList();
    },
    methods: {
      // 获取资讯列表
      getNewsList() {
        getNewsList().then(res => {
          this.newsList = res.value || [];
          console.log(this.newsList);
          this.isEmpty = !this.newsList.length;
          this.isLoading = false;
        });
      },
      // 跳转到详情页
      toEdit(command) {
        this.$router.push({
          name: 'AdminInformationEdit',
          query: {id: command.newsId, language: command.language}
        });
      },
      // 删除资讯
      deleteNews(index, newsId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteNews(newsId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.newsList.splice(index, 1);
                if (!this.newsList.length) {
                  this.getNewsList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(() => {
        }).catch(() => {
        });
      },
      // 置顶
      setTop(newsId) {
        setNewsTop(newsId).then(res => {
          this.$message.success('置顶成功');
          this.getNewsList();
        });
      },
      // 跳转到新增页面
      toAdd() {
        if (!this.isLoading) {
          if (this.newsList.length >= this.maxLength) {
            this.$message.warning(`廣告發佈上限是${this.maxLength}個`);
          } else {
            this.$router.push({name: 'AdminInformationEdit'});
          }
        }
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .list{
    margin-top:24px;
    .hd{
      position:relative;padding-left:16px;overflow:hidden;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
      .btn{display:inline-block;margin-top:3px;padding:0 8px;line-height:28px;color:#ff6f61;font-size:14px;font-weight:normal;border:1px solid #ff6f61;border-radius:4px;}
    }
  }
  .img-responsive{background: url("../../../assets/images/radio.png")}
  .img-responsive:active{background: url("../../../assets/images/radio_selected.png")}
</style>
