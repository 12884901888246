<template>
  <div class="empty">
    <img src="../assets/images/empty.png"/>
    <div>{{ title }}</div>
  </div>
</template>
<script>
  export default {
    name: 'Empty',
    props: {
      'title': String
    }
  };
</script>
<style scoped lang="less">
  .empty{
    padding:50px;text-align:center;color:#999;
    img{width:80px;height:80px;margin-bottom:5px;}
  }
</style>
