<template>
  <div class="user-info cl">
    <el-upload class="fl"
               accept="image/*"
               action="/v2/admin/common/file"
               :show-file-list="false"
               :on-success="handleAvatarSuccess">
      <avatar :url="photoUrl" :is-self="false"/>
    </el-upload>
    <div class="infos">
      <tabs class="tabs-language" v-model="language">
        <tab-pane v-for="item of languageOptions" :label="item.name" :name="item.value" :key="item.value"></tab-pane>
      </tabs>
      <div class="name">{{businessName}}</div>
      <div class="form-label">{{$t('Business Profile')}}</div>
      <div class="form-content">
        <el-input type="textarea"
                  :placeholder="$t('PleaseEnter')"
                  v-model="introduction"
                  maxlength="1000">
        </el-input>
      </div>
      <!--商戶聯絡方式-->
      <div class="form-label">{{$t('Business Contact')}}</div>
      <!--Email-->
      <div class="form-content">
        <input class="ipt w2" type="text" autocomplete="off" :placeholder="$t('Email')" v-model.trim="email"/>
      </div>
      <div class="form-content cl">
        <el-select class="select w1" size="small" :placeholder="$t('Area code')" v-model="phoneType">
          <el-option v-for="item in areaCodeOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="phone"/>
      </div>
      <div class="form-content cl" v-for="(item,index) of contacts" :key="index">
        <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')" v-model="item.phoneType">
          <el-option v-for="item in phoneTypeOptions"
                     :key="item.code"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select w1 m-r" size="small" :placeholder="$t('Area code')" v-model="item.phonePrefix"
                   v-if="showPhoneType(item.phoneType)">
          <el-option v-for="item in areaCodeOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <input class="ipt m-r" :type="showPhoneType(item.phoneType)?'number':'text'" autocomplete="off"
               :placeholder="$t('PleaseEnter')"
               v-model="item.userPhone"/>
        <!--删除聯絡方式-->
        <a class="btn-del" @click="delContact(index)">
          <img src="../../../../assets/images/icon/ashcan-red.png"/>
        </a>
      </div>
      <div>
        <!--添加聯絡方式-->
        <a class="btn-add" @click="addContact">
          <img class="vam" src="../../../../assets/images/icon/plus-circle-red.png"/>
          <span class="vam"> {{$t('Add contact')}}</span>
        </a>
      </div>
      <div class="form-label">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('Update')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import Tabs from '../../../../components/Tabs/Tabs';
  import TabPane from '../../../../components/Tabs/TabPane';
  import Avatar from '../../../../components/Avatar';
  import {languageOptions} from '../../../../utils/language';
  import {isEmail} from '../../../../utils/validate';
  import {getBusinessInfo, updateBusinessInfo} from '../../../../api/admin-business';
  import {getDictList} from '../../../../api/admin-common';

  export default {
    name: 'BusinessInfo',
    data() {
      return {
        businessName: '',
        introduction: '',
        areaCodeOptions: [],
        phoneType: '',
        phone: '',
        photoUrl: '',
        email: '',
        languageOptions: languageOptions,
        language: this.$i18n.locale,
        phoneTypeOptions: [],
        contacts: []
      };
    },
    components: {Tabs, TabPane, Avatar},
    created() {
      this.getDicts();
      this.getBusinessInfo();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
        // 获取联系方式类型
        getDictList('phoneType').then(res => {
          this.phoneTypeOptions = res.value || [];
        });
      },
      // 获取商户信息
      getBusinessInfo() {
        getBusinessInfo(this.language).then(res => {
          const value = res.value || {};
          Object.assign(this.$data, value);
        });
      },
      // 上传头像
      handleAvatarSuccess(res, file) {
        const {code, value} = res;
        if (code === 200) {
          this.photoUrl = value.url;
        }
      },
      // 添加聯絡方式
      addContact() {
        this.contacts.push({phoneType: '', phonePrefix: '', userPhone: ''});
      },
      // 删除联络方式
      delContact(index) {
        this.contacts.splice(index, 1);
      },
      showPhoneType(phoneType) {
        return ['shoutidianhua', 'Phone', 'Fax'].includes(phoneType);
      },
      // 其它联系资料
      getContacts() {
        let userContacts = [];
        for (let item of this.contacts) {
          if (item.phoneType && item.userPhone) {
            userContacts.push({
              contactType: 'N',
              userName: '',
              isCalled: '',
              position: '',
              phoneType: item.phoneType,
              phonePrefix: this.showPhoneType(item.phoneType) ? item.phonePrefix : '',
              userPhone: item.userPhone,
              userEmail: ''
            });
          }
        }
        return userContacts;
      },
      // 更新信息
      onSubmit() {
        const data = {
          photoUrl: this.photoUrl,
          phoneType: this.phoneType,
          phone: this.phone,
          email: this.email,
          // businessName: this.businessName,
          introduction: this.introduction,
          languageType: this.language,
          contacts: this.getContacts()
        };

        if (data.email && !isEmail(data.email)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }

        if (!data.introduction || !data.phone || !data.phoneType) {
          this.$message.warning('不能為空');
          return false;
        }

        updateBusinessInfo(data).then(res => {
          this.$message.success('更新成功');
        });
      }
    },
    watch: {
      language: function () {
        this.getBusinessInfo();
      }
    }
  };
</script>
<style scoped lang="less">
  .user-info{
    padding-bottom:24px;border-bottom:1px dashed #ff6f61;
    .infos{margin-left:120px;}
    .name{color:#000;font-size:20px;}
    .form-label{margin-top:24px;}
    .form-content{margin-top:8px;}
    .ipt{
      width:300px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:80px;margin-right:8px;}
      &.w2{width:408px;}
    }
    .select{
      width:300px;vertical-align:top;
      &.w1{width:100px;margin-right:8px;}
    }
    .m-r{margin-right:8px;}
    /deep/ .avatar{width:90px;height:90px;}
    /deep/ .el-textarea .el-textarea__inner{height:160px;padding:20px;}
  }
  .tabs-language{
    margin-bottom:24px;overflow:hidden;
    /deep/ .tab-nav{margin-top:0;}
  }
  .btn-add{display:inline-block;margin-top:8px;color:#ff6f61;cursor:pointer;}
  .btn-del{display:inline-block;padding:6px 0;font-size:0;}
</style>
