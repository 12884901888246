import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 上传附件
export function upload(data, onUploadProgress) {
  return request({
    url: '/admin/common/file',
    baseURL: '/v2',
    method: 'post',
    headers: {'Content-Type': 'multipart/form-data'},
    // 添加上传进度监听事件
    onUploadProgress,
    data
  });
}

// 获取数据字典列表
export function getDictList(domain) {
  return request({
    url: `/admin/common/dict/list/${domain}/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取国家/地区字典列表
export function getCountryList() {
  return request({
    url: `/admin/common/country/list/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取省/州
export function getProvinceList(countryCode) {
  return request({
    url: `/admin/common/province/list/${countryCode}/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 通过国家code获取城市字典列表
export function getCityList(countryCode) {
  return request({
    url: `/admin/common/city/list/${countryCode}/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 通过国家code或省/州code获取城市字典列表
export function getCitys(countryCode, provinceCode) {
  return request({
    url: `/admin/common/city/list/by/country/province/${countryCode}/${language}`,
    baseURL: '/v2',
    method: 'get',
    params: {provinceCode}
  });
}

// 获取所有城市字典列表
export function getAllCityList() {
  return request({
    url: `/admin/common/all/city/list/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}
